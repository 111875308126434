<template>
    <div class="slider" v-if="sliders.length > 0">
        <carousel
            :autoplay="sliders.length > 1"
            :loop="sliders.length > 1"
            :items="1"
            :nav="false"
            :centerMode="true"
            :center="true"
            :lazyLoad="true"
            :responsiveClass="true"
            :responsive="slideResponsive"
        >
            <template v-for="(item, key) in sliders">
                <a
                    :href="item.url"
                    :target="item.open_in === 'new' ? '_blank' : '_self'"
                    :key="key"
                    class="no-decoration"
                >
                    <div class="slider__image">
                        <img
                            :src="item.image_url"
                            :alt="item.image_name"
                            :title="item.image_name"
                            style="object-fit:cover;"
                        />
                    </div>

                    <div class="slider__desc">
                        <span class="slider__headline" v-if="item.category">
                            {{item.category}}
                        </span>
                        <p v-if="item.title">
                            {{item.title}}
                        </p>
                    </div>
                </a>
            </template>
        </carousel>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import carousel from "vue-owl-carousel";

export default {
    name: "slider",
    components: {
        carousel,
    },
    data() {
        return {
            sliders: [],
            slideResponsive: {
                0: {
                    items: 1,
                    stagePadding: 0,
                    margin: 0,
                },
                600: {
                    items: 1,
                    stagePadding: 0,
                    margin: 0,
                },
                1024: {
                    items: 2,
                    stagePadding: 250,
                    margin: 28,
                },
                1280: {
                    items: 2,
                    stagePadding: 250,
                    margin: 28,
                },
                1366: {
                    items: 2,
                    stagePadding: 250,
                    margin: 28,
                },
            }
        };
    },
    computed: {
        ...mapGetters({
            topPage: "common/topPage",
        }),
    },
    created() {
        this.sliders = this.topPage.slider;
    },
    watch: {
        topPage(data) {
            this.sliders = data.slider;
        },
    },
};
</script>