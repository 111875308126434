<template>
    <div class="container">
        <div class="row">
            <div class="col-12 mt-3">
                <Slider />
            </div>
        </div>
        <div class="row">
            <div class="col-12 pr-0">
                <PopularCategory />
            </div>
            <div class="col-12">
                <PromotionTop/>
            </div>
        </div>
        <div>
            <div class="col-12">
                <Banner />
            </div>
            <div class="col-12">
                <Information />
            </div>
            <div class="col-12">
                <ApplyTeacher />
            </div>
        </div>
        <AdsTop />
        <MetaTags/>
    </div>
</template>
<script>
import Slider from "./components/slider.vue";
import Information from "./components/information/list.vue";
import PopularCategory from "./components/popular-category.vue";
import PromotionTop from './promotion/index.vue'
import ApplyTeacher from "@/modules/sp/page/applyToTeacher/components/apply-to-teacher.vue";
import AdsTop from "./components/ads-top.vue";
import Banner from "@components/shared/banner/index.vue"

import { mapActions } from "vuex";
export default {
    name: "top-page",
    components: {
        Slider,
        Information,
        PopularCategory,
        PromotionTop,
        ApplyTeacher,
        AdsTop,
        Banner
    },
    async created(){
        await this.getTopPage({
            limit: 3,
            device_type: 'sp'
        });
    },
    methods: {
        ...mapActions({
            getTopPage: "common/getTopPage",
        }),
    }
};
</script>
