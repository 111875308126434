<template lang="">
    <div class="ads" v-if="!invisible">
        <button class="close" @click="handleClose">&#x2715;</button>
        <router-link 
            to="/c/all?type=rank&tab=teacher">
            <img src="/img/ads_top.png" />
        </router-link>
    </div>
</template>
<script>
export default {
    name: "AdsTop",
    data(){
        return {
            invisible: false
        }
    },
    created() {
        this.invisible = this.$cookies.get("adsTop") == 1;
    },
    methods: {
        handleClose(){
            this.invisible = true;
            this.$cookies.set("adsTop", 1, "24h");
        }
    },
}
</script>
<style scoped>
.ads{
    position: fixed;
    right: 5px;
    bottom: 5px;
    width: 207px;
    height: 177px;
    padding-right: 7px;
    padding-top: 7px;
    z-index: 1;
}
.close{
    width: 30px;
    height: 30px;
    background: var(--unnamed-color-f39800) 0% 0% no-repeat padding-box;
    background:#F39800 0% 0% no-repeat padding-box;
    color: #FFFFFF;
    position: absolute;
    border-radius: 25px;
    right: 0px;
    top: 0px;
    font-size: 18px;
    opacity: 1;
}
.close:hover{
        cursor: pointer;
    }
img{
    width: 200px;
    height: 167px;
}
</style>