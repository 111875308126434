<template>
    <div>
        <div class="content" >
            <div class="row">
                <div class="col-12"> 
                    <label class="date">{{_nullDate(item)}} </label> 
                </div>
                <div :class="`${isReadMore ? 'col col-12 ':'col col-12 custom-text-overflow'}`">
                    {{item.information_text}}
                </div>
                <div v-if="item.information_text.length > 50" 
                    role="button" class="text-primary  text-right col-12 align-self-center" 
                    @click="handleClick">{{isReadMore ? $t('top_page.read_less') : $t('top_page.read_more')}}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    "name": "Item",
    props: {
        item: {}
    },
    data() {
        return {
            isReadMore: false
        };
    },
    methods: {
        _nullDate(item) {
        return item.publish_from_date_time
            ? this.formatDate(item.publish_from_date_time, "Y.MM.D")
            : this.formatDate(item.created_at, "Y.MM.D");
        },
        handleClick() {
            this.isReadMore = !this.isReadMore;
        },
  },
}
</script>
<style scoped>
.custom-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>