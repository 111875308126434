<template>
    <div id="card_size" ref="card_size" class="popular-category">
        <h5 class="title">{{ $t('top_page.category.popular_category') }}</h5>
        <carousel
            :items="2"
            :stagePadding="44"
            :margin="12"
            :nav="false"
            :navText="false"
            :dots="false"
            :slideBy="2"
            :rewind="false"
            v-if="categories.length > 0"
        >
            <template v-for="(item, index) in categories">
                <router-link :key="index" :to="{ name: 'lesson.category.list', params: {slug: item.slug_location_arr}}">
                    <div class="category-item">
                        <img :src="item.image_url" :alt="item.image_name"
                            class="bd-placeholder-img bd-placeholder-img-lg d-block w-100 h-auto"
                            :title="item.image_name"
                        />
                    </div>
                    <h5 class="item-title text-wrap text-break">{{ item.name }}</h5>
                </router-link>
            </template>
        </carousel>
    </div>
</template>
<script>

import { mapGetters } from "vuex";
import carousel from "vue-owl-carousel";

export default {
    name: "popular-category",
    components: {
        carousel
    },
    data(){
        return {
            categories: [],
            navMenu: [
                '<span class="icon">&#8592;</span>',
                '<span class="icon">&#8594;</span>'
            ]
        }
    },
    computed: {
        ...mapGetters({
            topPage: "common/topPage",
        })
    },
    created(){
        this.categories = this.topPage.popularCategory
    },
    watch: {
        topPage(data){
            this.categories = data.popularCategory
        }
    },
}
</script>
<style scoped>
    .category-item {
        display: flex;
        border-radius: 50%;
        overflow: hidden;
    }
</style>