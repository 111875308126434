<template>
    <div class="banner">
        <div class="row">
            <div class="col-12 mt-1 mb-1" v-for="(item, key) in banners" :key="key">
                <a :href="item.url" :target="item.open_in === 'new' ? '_blank' : '_self'">
                    <img :src="item.image_url" :alt="item.title" style="object-fit:cover;"
                        class="bd-placeholder-img bd-placeholder-img-lg d-block w-100 h-auto" />
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    name: "banner",
    data() {
        return {
            banners: [],
        };
    },
    computed: {
        ...mapGetters({
            topPage: "common/topPage",
        }),
    },
    created() {
        this.banners = this.topPage.banners;
    },
    watch: {
        topPage(data) {
            this.banners = data.banners;
        },
    },
};
</script>